import React from 'react'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, LogoSpreadIcon } from 'components/icons'
import { Nav } from 'components/nav'
import { HeaderLink, LdJsonLogo } from 'components/semanticdata'
import FlagImage from '../../assets/blog/nextgen-eu-flag.png'
import ResilienceImage from '../../assets/blog/nextgen-eu-resilience.png'
import Analytics from '../../components/analytics'

const BlogPage = () => (
  <Layout className="bg-primary-2">
    <Analytics />
    <SEO title="Next Generation EU Sofware Bid" pageUrl="/blog/nextgen-eu-sw/" type="article" description="Технологична модернизация в предприятието" />
    <LdJsonLogo />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 h-56">
        <HeaderLink />
      </Header>
      <Deco before after decoType="3" className="bg-primary-3 h-full v-full" gridRow={2} />
      <Section className="z-20 mb-8 text-primary-2" gridRow={2}>
        <div className="p-5 flex flex-wrap">
          <img src={FlagImage} alt="Next Generation EU" className=""/>
          <img src={ResilienceImage} alt="План за въстановяване и развитие" className=""/>
        </div>
        <h1 className="pt-5 text-xl sm:text-2xl lg:text-3xl leading-tight">Публична покана по “Технологична модернизация в предприятието”</h1>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          ТУМБА СЪЛЮШЪНС ООД обявява процедура за избор на изпълнител(и) чрез публична покана за „Доставка на софтуер” по обособени позиции:
          <ul className="pl-10 mt-2 list-disc">
            <li>ОП 1: Специализиран софтуер под формата на сорс код – система за дистрибутиран backend 1 бр.</li>
            <li>ОП 2: Специализиран софтуер под формата на сорс код – уеб платформа 1 бр.</li>
          </ul>
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Начален срок на обявата: 11.03.2024
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Краен срок за получаване на оферти: 18.03.2024
        </p>
        <p className="pt-5 text-sm sm:text-lg font-normal">
          Документите за кандидатстване са публикувани в ИСУН-МВУ и на интернет страницата на фирмата.
          <a href="/blog/nextgen-eu-sw-bid.zip" target="_blank" className="col-span-4 pt-5 flex items-baseline text-primary-1">
            <span>Свалете документите</span>
            <LinkArrowIcon className="ml-2 w-4 stroke-current transform rotate-90" />
          </a>
        </p>
        <p className="pt-12 text-xs italic font-normal">
          <div className="flex w-full">
            <hr className="flex-1 mr-3"/>
            <a href="https://www.nextgeneration.bg/">https://www.nextgeneration.bg/</a>
            <hr className="flex-1 ml-3"/>
          </div>
          Проект  BG-RRP-3.004-2375-C01 “Технологична модернизация в предприятието“ финансиран от Европейския съюз – NextGenerationEU.
          Този документ е създаден с финансовата подкрепа на Европейския съюз – NextGenerationEU. Цялата отговорност
          за съдържанието на документа се носи от ТУМБА СЪЛЮШЪНС ООД и при никакви обстоятелства не може да се приема,
          че този документ отразява официалното становище на Европейския съюз и МИР.“
        </p>
      </Section>
      <Section className="z-20 mt-40" gridRow={3}>
        <LogoSpreadIcon className="m-auto h-20" />
      </Section>
      <Deco className="bg-primary-1" gridRow={3} />
      <Nav className="z-20 text-primary-2" gridRow={4} />
    </MainWithMenu>
  </Layout>
)

export default BlogPage
